<template>
  <div class="bg-white rounded shadow p-12 my-12 container">
    <div class="flex md:flex-col">
      <div
        class="px-8 flex-glow-0 w-[300px] md:w-full md:flex md:justify-center"
      >
        <Avatar></Avatar>
      </div>
      <div class="px-8 flex-grow">
        <div class="text-black text-2xl">Hi, {{ basic.name }}</div>
        <div class="mt-4 flex items-center">
          <span class="text-gray text-lg">{{ basic.membership }}</span>
          <el-button
            type="primary"
            size="small"
            class="ml-4"
            @click="$router.push({ name: 'Account.History' })"
          >
            會員方案紀錄
          </el-button>
        </div>
        <div class="text-gray mt-2 text-lg">
          {{ basic.memberDesc }}
        </div>

        <el-button
          type="info"
          size="default"
          class="mt-4"
          @click="$router.push({ name: 'Shop' })"
          >立即續訂享優惠</el-button
        >

        <div class="mt-12 !space-x-0 flex flex-col items-start">
          <el-button
            v-if="user.accountType !== 'social'"
            type="text"
            size="default"
            class=""
            @click="$router.push({ path: '/account/resetPassword' })"
            >修改密碼</el-button
          >
          <el-button v-if="!(version < 1)" type="text" size="default" class=""
            >查詢購買紀錄</el-button
          >
          <el-button
            type="text"
            size="default"
            class=""
            @click="$router.push({ path: '/account/coupon' })"
            >我的優惠券</el-button
          >
          <el-button
            type="text"
            size="default"
            class=""
            @click="$router.push({ path: '/account/contact' })"
            >聯絡我們</el-button
          >
          <el-button type="text" size="default" class="" @click="logout"
            >登出</el-button
          >

          <el-checkbox
            v-model="form.alert"
            label="接收最新優惠資訊"
            :true-label="1"
            :false-label="0"
            size="large"
          ></el-checkbox>
        </div>
      </div>

      <div class="px-8 flex-grow md:mt-8">
        <div class="text-xl pb-2 border-b mb-4">個人帳號</div>
        <El-Form
          ref="formRef"
          :model="form"
          label-position="left"
          label-width="100px"
          :rules="rules"
        >
          <El-Form-Item :label="'Email'">
            {{ basic.email }}
          </El-Form-Item>
          <El-Form-Item label="電話">
            {{ basic.phone }}
          </El-Form-Item>

          <Gender v-model="form.gender" label="性別"></Gender>
          <Birth v-model="form.birthday" label="生日"></Birth>
          <Address v-model="form.address" label="地址"></Address>
          <Education v-model="form.education" label="教育程度"></Education>
        </El-Form>
      </div>
    </div>
    <div class="flex justify-center mt-12">
      <el-button type="primary" size="large" @click="onSubmit">儲存</el-button>
    </div>

    <!-- 方案詳細 -->
  </div>
</template>
<script>
import { reactive, ref, inject } from 'vue';

import Avatar from '@/components/form/Avatar.vue';

import { ElForm, ElFormItem, ElCheckbox } from 'element-plus';

import Gender from '@/components/form/Gender.vue';
import Education from '@/components/form/Education.vue';
import Address from '@/components/form/Address.vue';
import Birth from '@/components/form/Birth.vue';
import useProfile from '@/modules/auth/useProfile';
export default {
  components: {
    ElForm,
    ElFormItem,
    Avatar,
    Gender,
    Education,
    Birth,
    Address,
    ElCheckbox,
  },
  setup() {
    const { user, logout: _logout } = inject('auth');

    const version = inject('version');
    const logout = () => {
      _logout().then((res) => {
        // router.replace({ name: 'Home' });
        window.location.href = '/';
      });
    };
    const basic = reactive({
      name: user.name,
      email: user.email,
      phone: null,
      memberDesc: '',
      membership: '',
      alert: null,
    });

    const { form, submit, loading, fetcher } = useProfile();

    fetcher().then((res) => {
      if (res.code === 1) {
        Object.keys(res.data).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(basic, key)) {
            basic[key] = res.data[key];
          }
        });
      }
    });

    const formRef = ref(null);

    const rules = {
      code: [],
    };

    const onSubmit = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          submit().then((res) => {
            if (res.code === 1) {
              // user.name = form.name;
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    };

    return {
      basic,
      onSubmit,
      form,
      rules,
      formRef,
      loading,
      logout,
      version,
      user,
    };
  },
};
</script>
